import React, { Suspense, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import store from './store';
import history from './history';
import Layouts from './layouts';
import { THEME_CONFIG } from './configs/AppConfig';
import mockServer from './mock';
import './lang';
import { BellOutlined } from "@ant-design/icons";
import { notification } from 'antd';
import { getMessaging, onMessage } from "firebase/messaging";
import { tokenGenerator } from 'store/slices/authSlice';
import { AUTH_DEVICE_TOKEN } from 'constants/AuthConstant';

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`
};

const environment = process.env.NODE_ENV;

// if (environment !== 'production') {
// 	mockServer({ environment })
// }

function App() {

  const [currentToken, setCurrentToken] = useState()

  // useEffect(()=>{
  //   if(currentToken){
  //     localStorage.setItem(AUTH_DEVICE_TOKEN,currentToken)
  //   }
  // },[currentToken])

    //remove console from production
    if (process.env.NODE_ENV === "production") {
      console.log = () => {};
      console.warn = () => {};
      //console.error = () => {};
    }

  const generateToken = async() => {
    const permission = await Notification.requestPermission();
    console.log('notification permission', permission)

    // if (permission !== "granted") {
    //   throw new Error("Notification permission not granted.");
      
    // }

    const currentToken = await tokenGenerator();
    setTimeout(()=>{
      localStorage.setItem(AUTH_DEVICE_TOKEN,currentToken)
    },2000)
    console.log('token before', currentToken)
    if(currentToken){
      setCurrentToken(currentToken)
    }
    if(currentToken || currentToken == null){
      
      console.log('token genrated in app', currentToken)
    }
    }

    console.log('current token rrr', currentToken)

    useEffect(()=>{ 
      generateToken();
    },[window.location])



useEffect(() => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
      .then((registration) => {
        const messaging = getMessaging();
        // Normally, you would now listen for notifications like this:
        onMessage(messaging, (payload) => {
          console.log("Message received. ", payload);
          notification.open({
            message: payload.notification.title,
            description:
              payload.notification.body,
            icon: <BellOutlined style={{ color: "#108ee9" }} />, // Custom icon
          });
        });

        console.log("Service Worker registered successfully");
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
  }
}, []);


  // Register the service worker


  const messaging = getMessaging();

  useEffect(() => {
    // Listen for messages when the app is in the foreground
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      notification.open({
        message: payload.notification.title,
        description:
          payload.notification.body,
        icon: <BellOutlined style={{ color: "#108ee9" }} />, // Custom icon
      });
    });
  }, []);

  onMessage(messaging, (payload) => {
    console.log("Message received. ", payload);
    notification.open({
      message: payload.notification.title,
      description:
        payload.notification.body,
      icon: <BellOutlined style={{ color: "#108ee9" }} />, // Custom icon
    });
  });

  return (
    <div className='App'>
      <Provider store={store}>
        <BrowserRouter history={history}>
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={THEME_CONFIG.currentTheme}
            insertionPoint='styles-insertion-point'
          >
            <Layouts />
          </ThemeSwitcherProvider>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
