import {
  BillsKey,
  ClientExecutiveKey,
  InventoryKey,
  InvoiceKey,
  ThirdPartyEmployeeKey
} from './RoutesKeys';

export const AUTH_TOKEN = 'auth_token_admin';
export const AUTH_TYPE = 'auth_type';
export const AUTH_ROLES = 'auth_roles';
export const AUTH_ID = 'auth_id';
export const AUTH_NAME = 'auth_name';
export const AUTH_DEVICE_TOKEN = 'auth_device_token';
export const ADMIN_TYPE = 'ADMIN';
export const SUB_ADMIN_TYPE = 'SUB-ADMIN';
export const EMPLOYEE_TYPE = 'EMPLOYEE';
export const CUSTOMER_TYPE = 'CUSTOMER';
export const CLIENT_TYPE = 'CLIENT';
export const THIRD_PARTY_TYPE = 'THIRD PARTY';
export const THIRD_PARTY_EMPLOYEE_TYPE = 'THIRD PARTY EMPLOYEE';
export const CLIENT_EXECUTIVE_TYPE = 'CLIENT EXECUTIVE';
export const AUTHENTICATED_CUSTOMER_ENTRIES = [InventoryKey, InvoiceKey];
export const AUTHENTICATED_CLIENT_ENTRIES = [
  // InventoryKey,
  // InvoiceKey,
  // ClientExecutiveKey,
  // BillsKey
];
export const AUTHENTICATED_CLIENT_EXECUTIVE_ENTRIES = [InventoryKey, BillsKey];
export const AUTHENTICATED_THIRD_PARTY_ENTRIES = [

];
